import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'

import celebrate from '../images/celebrate.svg'


const AboutPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header title="Passionate about web marketing" sub="We are a passionate group of story tellers from all corners of the globe" image={celebrate} />
    <h1>About</h1>
  </Layout>
)

export default AboutPage
